import Users from './Users';
import UserForm from './UserForm';

const routes = [
    { path: '/', element: < Users /> },
    { path: '/register', element: < UserForm /> },
];


export default routes;
