import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Table, Modal, Button } from 'react-bootstrap';
import { TextField } from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import EditIcon from '@mui/icons-material/Edit';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Users.css';

export default function Users() {

    const [users, setUsers] = useState([]);
    const [renderUser, setRenderUser] = useState(false);
    const [currentUser, setCurrentUser] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');


    useEffect(() => {
        fetch('https://jsonplaceholder.typicode.com/users')
            .then(response => response.json())
            .then(data => setUsers(data));
    }, [])

    async function removeHandler() {

        await fetch(`https://jsonplaceholder.typicode.com/users/${currentUser.id}`, {
            method: 'DELETE',
        });

        setRenderUser(prevState => !prevState);
        setShowDeleteModal(false);

    }

    async function editHandler() {

        const newUser = {
            firstName,
            lastName,
            email
        };

        await fetch(`https://jsonplaceholder.typicode.com/users/${currentUser.id}`, {
            method: 'PUT',
            body: JSON.stringify(newUser)
        });

        setRenderUser(prevState => !prevState);
        setShowEditModal(false);


    }






    return (
        <div className='users-wrapper'>
            <Table striped bordered hover size='sm' variant="dark" className='users-table'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => {
                        const splitedName = user.name.split(' ');

                        return (
                            <tr key={user.id}>
                                <td>{index + 1}</td>
                                <td>{splitedName[0]}</td>
                                <td>{splitedName[1]}</td>
                                <td>{user.email}</td>
                                <td>
                                    <button onClick={() => {
                                        setCurrentUser(user);
                                        setShowDeleteModal(true);
                                    }}><PersonRemoveIcon color='success' /></button>
                                    <button onClick={() => {
                                        setCurrentUser(user);
                                        setShowEditModal(true);
                                    }}><EditIcon color='success' /></button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            <Link to='/register' style={{textDecoration: 'none', border: '1px solid #333', padding: 3, color: 'white'}}>Add New User</Link>

            <Modal show={showDeleteModal}>
                <Modal.Header>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>{`Do you want to remove ${currentUser.name} ?`}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Close
                    </Button>
                    <Button variant="success" onClick={removeHandler}>
                        Yes, delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditModal}>
                <Modal.Header>
                    <Modal.Title>Edit Confirmation</Modal.Title>
                </Modal.Header>
                <form style={{ padding: 5 }}>
                    <TextField
                        fullWidth
                        label="First Name"
                        id="fullWidth"
                        className='input'
                        size='medium'
                        color='success'
                        margin='normal'
                        onChange={event => (setFirstName(event.target.value))}
                    />
                    <TextField
                        fullWidth
                        label="Last Name"
                        id="fullWidth"
                        className='input'
                        color='success'
                        margin='normal'
                        onChange={event => (setLastName(event.target.value))}
                    />
                    <TextField
                        fullWidth
                        label="Email"
                        id="fullWidth"
                        className='input'
                        color='success'
                        margin='normal'
                        onChange={event => (setEmail(event.target.value))}
                    />
                </form>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Close
                    </Button>
                    <Button variant="success" onClick={editHandler}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
}
