import React, { useState } from "react";
import { TextField, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send'; import './UserForm.css';


export default function UserForm() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const submitHandler = (event) => {
        event.preventDefault();

        const user = {
            firstName,
            lastName,
            email
        };

        fetch('https://jsonplaceholder.typicode.com/users', {
            method: 'POST',
            body: JSON.stringify(user)
        })
            .then(response => response.json())
            .then(data => console.log(data));

    };


    return (
        <div className='form-wrapper'>
            <form onSubmit={submitHandler}>
                <TextField
                    fullWidth
                    label="First Name"
                    id="fullWidth"
                    className='input'
                    size='medium'
                    color='success'
                    onChange={event => (setFirstName(event.target.value))}
                />
                <TextField
                    fullWidth
                    label="Last Name"
                    id="fullWidth"
                    className='input'
                    color='success'
                    onChange={event => (setLastName(event.target.value))}
                />
                <TextField
                    fullWidth
                    label="Email"
                    id="fullWidth"
                    className='input'
                    color='success'
                    onChange={event => (setEmail(event.target.value))}
                />
                <Button variant="contained" size='large' type='submit' color='success'>Submit<SendIcon /></Button>
            </form>
        </div>
    );
}
