import React from "react";
import { useRoutes } from 'react-router-dom';
import UserForm from './Component/UserForm';
import routes from './Component/Routes';


export default function App() {

    const router = useRoutes(routes);


    return (
        <div>
            {router}
        </div>
    );
}


